<template>
<div>

    <!-- banner start -->
    <section
      class="banner_sec self_banner_sec"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="self_banner_hed">
              <h3 class="heading_3 mb_20 text_white font_bold">Ergo-Ally</h3>
              <p class="mb_36 text_white font_size_30">
                Your powerful, customizable, global ergo solution.
              </p>
              <a href="learn-more" class="btn btn_primary">Request a Demo</a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- banner end -->

    <!-- meet start -->
    <section class="meet_sec padding_100">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-xl-2">
            <div class="meet_logo">
              <img src="../../assets/home/images/self_assessments/meet_logo.png" alt="img" />
            </div>
          </div>

          <div class="col-md-12 col-xl-10">
            <div class="meet_content">
              <h3 class="heading_3">Meet Your Ergo-Ally</h3>
              <p>
                Ergo-Ally will arm you and your employees with the training and
                strategies you need to successfully manage your organization’s
                ergo needs. Whether you are expanding locations, dealing with
                return-to-work or work-from-home, have multiple campuses or a
                global presence, Ergo-Ally is your “all-in-one” ergo management
                tool.
              </p>

              <h3 class="heading_3">How Can Ergo-Ally Help Your Employees?</h3>
              <p>
                Whether employees are working from home, the office or hybrid setting, Ergo-Ally has impactful resources for everyone.  Your employees will have 24/7 global access to self-assessments, ergo setup guidelines, productivity tips, wellness strategies, stretches, posture perfect exercises, video playlists and more.  No matter the work setting, they will learn to set themselves up for success!
              </p>

              <h3 class="heading_3">
                How Can Ergo-Ally Help You and Your Organization?
              </h3>
              <p>
                Managing an ergo program with a dispersed workforce is no easy
                feat. Ergo-Ally gives you oversight of your entire ergo program, including all ergo activity, insight and analysis of trends, hot spots, and cost savings measures. Through your dynamic dashboard, you
                have 24/7 access to a powerful data base containing employee
                records, metrics and much more!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- meet end -->

    <!-- Heres start -->
    <section class="heres_sec bg_grey padding_100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <h3 class="heading_3 font_bold text_black mb_60 text-center">
              Here’s What Ergo-Ally Offers
            </h3>
          </div>
          <div class="col">
            <div class="heres_box">
              <div class="ergo-ally-img-section">
                <img src="../../assets/home/images/self_assessments/Self.svg" alt="icon" />
              </div>
              <h6 class="heading_6">Self-Assessment <br />& Training</h6>
            </div>
          </div>

          <div class="col">
            <div class="heres_box">
              <div class="ergo-ally-img-section">
                <img src="../../assets/home/images/self_assessments/Backend.svg" alt="icon" />
              </div>
              <h6 class="heading_6">Backend Data <br />& Metrics</h6>
            </div>
          </div>

          <div class="col">
              <div class="heres_box">
                <div class="ergo-ally-img-section">
                  <img src="../../assets/home/images/self_assessments/Scheduling.svg" alt="icon" />
                </div>
                <h6 class="heading_6">Scheduling & <br />Admin Tools</h6>
              </div>
          </div>

          <div class="col">
            <div class="heres_box">
              <div class="ergo-ally-img-section">
                <img src="../../assets/home/images/self_assessments/Scalable.svg" alt="icon" />
              </div>
              <h6 class="heading_6">Scalable <br />Solutions</h6>
            </div>
          </div>

          <div class="col">
            <div class="heres_box">
              <div class="ergo-ally-img-section">
                <img src="../../assets/home/images/self_assessments/Custom.svg" alt="icon" />
              </div>
              <h6 class="heading_6">Custom Comms <br />Packages</h6>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Heres end -->

    <!-- counter start -->
    <section
      id="counter-stats"
      class="wow fadeInRight py_70 bg_primary"
      data-wow-duration="1.4s"
    >
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-12 col-lg-12 col-xl-9">
            <div class="row">
              <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3 stats">
                <div class="media">
                  <div class="media-body countries">
                    <div class="counting" data-count="21K">21</div>
                    <h5>Countries</h5>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3 stats">
                <div class="media">
                  <div class="media-body users">
                    <div class="counting" data-count="100">100k+</div>
                    <h5>Users</h5>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3 stats">
                <div class="media">
                  <div class="media-body">
                    <div class="counting" data-count="100">100+</div>
                    <h5>Engaging Videos</h5>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3 stats">
                <div class="media">
                  <div class="media-body">
                    <div class="counting" data-count="20">20+</div>
                    <h5>Companies</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 col-lg-12 col-xl-3">
            <div class="request_btn">
            <router-link class="" :to="{ name: 'learn_more' }">
                <h2>Request</h2>
                <p>A Demo Today!</p>
            </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- counter end -->
</div>
</template>

<script>
// import { mapGetters } from "vuex";
import store from "@/store/index";

export default {
  name: "self_assessments",
  created(){
     document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
  },
  mounted() {
   
    store.dispatch("Home/getHomeData").then(() => {});
  },
};
</script>